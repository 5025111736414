import {
  formatDate, formatDuration, formatTime, formatMasterSubmaster, formatBoolean,
} from '../../core/helpers';
import { formatAspectRatio, formatDateTime } from '../../core/utils';
import { FormField } from '../../interfaces/formfield';

export const PROPERTIES_REQUIRED_FIELDS_SECTION_ONE: FormField[] = [
  {
    label: 'File Name',
    key: ['pmam', 'fileName'],
  },
  {
    label: 'Media Type',
    key: ['mediaType'],
  },
  {
    label: 'Material Type',
    key: ['materialType'],
  },
  {
    label: 'Production Type',
    key: ['productionType'],
  },
  // We should be rendering version titles as its currently imposable to render conditionally
  // So we can't say if a feature or if a season...
  // {
  //   label: 'Series Title',
  //   key: ['seriesTitle'],
  // },
];

export const PROPERTIES_REQUIRED_FIELDS_SECTION_TWO_LEFT: FormField[] = [
  {
    label: 'Airtable ID',
    key: ['pmam', 'airtableId'],
  },
  {
    label: 'Text',
    key: ['textVersion'],
  },
  {
    label: 'Aspect Ratio',
    key: ['aspectRatio'],
    formatter: formatAspectRatio,
  },
  {
    label: 'GTM Version Name',
    key: ['versionName'],
  },
  {
    label: 'Original Air Network',
    key: ['originalAirNetwork'],
  },
  {
    label: 'Version Type',
    key: ['pmam', 'versionType', 'description'],
  },
];

export const PROPERTIES_REQUIRED_FIELDS_SECTION_TWO_RIGHT: FormField[] = [
  {
    label: 'Master Type',
    key: ['pmam', 'masterType'],
    formatter: formatMasterSubmaster,
  },
  {
    label: 'Mask Type',
    key: ['pmam', 'maskType', 'description'],
  },
  {
    label: 'GTM Title',
    key: ['title'],
  },
  {
    label: 'GTM Version Type',
    key: ['versionType'],
  },
  {
    label: 'Language',
    key: ['language'],
  },
  {
    label: 'Copyright Owner',
    key: ['pmam', 'copyrightOwner'],
  },
];

export const PROPERTIES_DESCRIPTIVE_DATA_LEFT: FormField[] = [
  {
    label: 'People',
    key: ['pmam', 'actorPeople'],
  },
  {
    label: 'User Description',
    key: ['pmam', 'userDescription'],
  },
];

export const PROPERTIES_COMMENTS: FormField[] = [
  {
    label: 'Comments',
    key: ['pmam', 'comments'],
  },
];

export const PROPERTIES_TECHNICAL_SPECS_LEFT: FormField[] = [
  {
    label: 'Frame Rate',
    key: ['frameRate'],
  },
  {
    label: 'Scan Type',
    key: ['unifiedScanType'],
  },
  {
    label: 'Definition',
    key: ['definition'],
  },
  {
    label: 'Video Bit Depth',
    key: ['pmam', 'videoBitDepth'],
  },
  {
    label: 'File Size',
    key: ['pmam', 'fileSize'],
  },
  {
    label: 'Wrapper',
    key: ['pmam', 'wrapper'],
  },
  {
    label: 'Tape',
    key: ['pmam', 'tape'],
  },
];

export const PROPERTIES_TECHNICAL_SPECS_RIGHT: FormField[] = [
  {
    label: 'Duration',
    key: ['duration'],
    formatter: formatDuration,
  },
  {
    label: 'Video Bit Rate',
    key: ['pmam', 'videoBitRate'],
  },
  {
    label: 'Parent Path',
    key: ['pmam', 'parentPath'],
  },
  {
    label: 'Audio Bit Depth',
    key: ['pmam', 'audioBitDepth'],
  },
  {
    label: 'Audio Bit Rate',
    key: ['pmam', 'audioBitRate'],
  },
  {
    label: 'FPS',
    key: ['pmam', 'fps'],
  },
  {
    label: 'Audio Channels',
    key: ['audioChannels'],
  },
];

export const PROPERTIES_TECHNICAL_AUDIO_DETAILS: FormField[] = [
  {
    label: 'Audio Details',
    key: ['audioDetails'],
  },
];

export const PROPERTIES_AIRTABLE_LEFT: FormField[] = [

  {
    label: 'House ID',
    key: ['pmam', 'airtable', 'houseNumber'],
  },
  {
    label: 'Project',
    key: ['pmam', 'airtable', 'project'],
  },
  {
    label: 'Producer',
    key: ['pmam', 'airtable', 'producer'],
  },
  {
    label: 'Length',
    key: ['pmam', 'airtable', 'lengthInSeconds'],
    formatter: formatTime,
  },
  {
    label: 'Tag',
    key: ['pmam', 'airtable', 'tag'],
  },
  {
    label: 'Deliverable Name',
    key: ['pmam', 'airtable', 'deliverableName'],
  },
  {
    label: 'Promo Code',
    key: ['pmam', 'airtable', 'promoCode'],
  },
  {
    label: 'Revision #',
    key: ['pmam', 'airtable', 'revisionNumber'],
  },
  {
    label: 'DAM Asset Publish',
    key: ['pmam', 'damAssetPublish'],
  },
  {
    label: 'Start Air',
    key: ['pmam', 'airtable', 'startAir'],
    formatter: formatDate,
  },
];

export const PROPERTIES_REFERENCE_IDS_LEFT: FormField[] = [
  // Not sure where to get this value
  // {
  //   label: '(Old) Reference IDs',
  //   key: [''],
  // },
  // {
  //   label: 'MAM ID',
  //   key: ['pmam', 'mamId'],
  // },
  {
    label: 'Material Id',
    key: ['materialId'],
  },
  {
    label: 'GTM ID',
    key: ['gtmId'],
  },
  {
    label: 'Compass ID',
    key: ['pmam', 'gtm', 'compassTitleNumber'],
  },
  {
    label: 'TMS ID',
    key: ['tmsId'],
  },
  {
    label: 'GTM Unified Version ID',
    key: ['gtmVersionUnifiedId'],
  },
  {
    label: 'MDV ID',
    key: ['gtmMdvUnifiedId'],
  },
  {
    label: 'Reference ID',
    key: ['pmam', 'referenceId'],
  },
  {
    label: 'Mob ID',
    key: ['pmam', 'mobId'],
  },
];

export const PROPERTIES_ORDER_HISTORY_LEFT: FormField[] = [
  {
    label: 'Order #',
    key: ['workorderId'],
  },
  {
    label: 'Order Date',
    key: ['createdAt'],
    formatter: formatDateTime,
  },
];

export const PROPERTIES_ORDER_HISTORY_RIGHT: FormField[] = [
  {
    label: 'Profile',
    key: ['profile', 'code'],
  },
  {
    label: 'Order by',
    key: ['createdBy'],
  },
];

export const PROPERTIES_DESCRIPTIVE_DATA: FormField[] = [
  {
    label: 'Mlai Status',
    key: ['pmam', 'mlaiStatus'],
  },
  {
    label: 'Total Run Time',
    key: ['totalRunTime'],
    formatter: formatDuration,
  },
  {
    label: 'Rating',
    key: ['rating'],
  },
];

export const PROPERTIES_DATES: FormField[] = [
  {
    label: 'Shoot Date',
    key: ['pmam', 'shootDate'],
    formatter: formatDate,
  },
  {
    label: 'Upload Date',
    key: ['pmam', 'uploadDate'],
    formatter: formatDate,
  },
  {
    label: 'Premier Date',
    key: ['pmam', 'premierDate'],
    formatter: formatDate,
  },
  {
    label: 'Original Air Date',
    key: ['originalAirDate'],
    formatter: formatDate,
  },
];

export const PROPERTIES_ADMINISTRATIVE: FormField[] = [
  {
    label: 'Brand',
    key: ['pmam', 'brand'],
  },
  {
    label: 'Storage System',
    key: ['storageSystem'],
  },
  {
    label: 'Acquisition Source',
    key: ['pmam', 'acquisitionSource'],
  },
  {
    label: 'Production Number',
    key: ['productionNumber'],
  },
];

export const PROPERTIES_ADMINISTRATIVE_LEFT: FormField[] = [
  {
    label: 'Brand',
    key: ['brand'],
  },
  {
    label: 'Producer',
    key: ['pmam', 'producer'],
  },
  {
    label: 'Restricted Status',
    key: ['pmam', 'restrictedStatus'],
  },
  {
    label: 'Restrictions',
    key: ['pmam', 'restrictions'],
  },
  {
    label: 'Physical Storage',
    key: ['pmam', 'physical'],
    formatter: formatBoolean,
  },
];

const FORM_FIELDS: FormField[] = [
  ...PROPERTIES_REQUIRED_FIELDS_SECTION_ONE,
  ...PROPERTIES_REQUIRED_FIELDS_SECTION_TWO_LEFT,
  ...PROPERTIES_REQUIRED_FIELDS_SECTION_TWO_RIGHT,
  ...PROPERTIES_TECHNICAL_SPECS_LEFT,
  ...PROPERTIES_TECHNICAL_SPECS_RIGHT,
  ...PROPERTIES_TECHNICAL_AUDIO_DETAILS,
  ...PROPERTIES_REFERENCE_IDS_LEFT,
  ...PROPERTIES_ORDER_HISTORY_LEFT,
  ...PROPERTIES_ORDER_HISTORY_RIGHT,
  ...PROPERTIES_DESCRIPTIVE_DATA,
  ...PROPERTIES_DESCRIPTIVE_DATA_LEFT,
  ...PROPERTIES_DATES,
  ...PROPERTIES_ADMINISTRATIVE,
  ...PROPERTIES_COMMENTS,
  ...PROPERTIES_ADMINISTRATIVE_LEFT,
  ...PROPERTIES_AIRTABLE_LEFT,
];

export function getFormFieldByKey(key: string) {
  return FORM_FIELDS.find((field) => field.key.join('.') === key);
}
