import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store';
import { API_ENDPOINTS } from '../consts/API_ENDPOINTS';
import { UserPrincipal } from '../../interfaces/user-principal';

const AppUserSlice = createSlice({
  name: 'appUser',
  initialState: {
    user: null,
    policies: [],
  },
  reducers: {
    setAppUser(state, action) {
      state.user = action.payload;
      const mamRoles = action.payload.authorities
        ? action.payload.authorities.filter((role : string) => role.includes('mam'))
        : [];
      state.policies = mamRoles;
    },
  },
});

const { actions, reducer } = AppUserSlice;

export const {
  setAppUser,
} = actions;

export const getAppUser = (() => (dispatch: AppDispatch) => axios.get(`${API_ENDPOINTS.LOGGED_IN_USER}/introspect`).then((res) => {
  const user: UserPrincipal = res?.data?.data;
  dispatch(setAppUser(user));
  return user;
}).catch((err) => {
  console.log(err);
  fetch('/logout')
    .then(() => {
      window.location.href = '/login';
    })
    .catch(() => {
      window.location.href = '/login';
    });
}));

export default reducer;
