export const formatSupplyOptions = (options: any) => options.map((option: any) => ({
  code: option?.code,
  position: option?.id,
  displayName: option?.description,
  __typename: 'LookupValue',
}));

export const studioSupplyOptions = {
  'order-types': [
    {
      code: 'Longform',
      displayName: 'Longform',
      position: 1,
    },
    {
      code: 'Sub-clipping',
      displayName: 'Sub-clipping',
      position: 2,
    },
    {
      code: 'Watermarking',
      displayName: 'Watermarking',
      position: 3,
    },
    {
      code: 'Edit',
      displayName: 'Edit',
      position: 4,
    },
    {
      code: 'StillFrame',
      displayName: 'Still Frame Capture',
      position: 5,
    },
  ],
  networks: [
    {
      code: 'BRAVO',
      description: 'Bravo',
      id: 1,
    },
    {
      code: 'COZI',
      description: 'COZI',
      id: 23,
    },
    {
      code: 'CP_CABLE_LA',
      description: 'CP Cable LA',
      id: 17,
    },
    {
      code: 'CP_NEWS',
      description: 'CP News',
      id: 15,
    },
    {
      code: 'CP_PEACOCK_PORTFOLIO',
      description: 'CP Peacock Portfolio',
      id: 16,
    },
    {
      code: 'CP_TELEMUNDO',
      description: 'CP Telemundo',
      id: 18,
    },
    {
      code: 'EET',
      description: 'E! Entertainment',
      id: 2,
    },
    {
      code: 'ELG_DIGITAL',
      description: 'ELG Digital',
      id: 19,
    },
    {
      code: 'GMO_POST',
      description: 'GMO Post',
      id: 3,
    },
    {
      code: 'LXTV',
      description: 'LXTV',
      id: 22,
    },
    {
      code: 'NBCE',
      description: 'NBC Entertainment',
      id: 10,
    },
    {
      code: 'NBC_FILM',
      description: 'NBC Film',
      id: 13,
    },
    {
      code: 'NBC_LOCAL_MIAMI_WTVJ',
      description: 'NBC Local Miami WTVJ',
      id: 20,
    },
    {
      code: 'O2',
      description: 'Oxygen',
      id: 9,
    },
    {
      code: 'PEACOCK',
      description: 'Peacock',
      id: 4,
    },
    {
      code: 'Peacock_Domestic',
      description: 'Peacock Domestic',
      id: 12,
    },
    {
      code: 'PROJECT_EARTH',
      description: 'Project Earth',
      id: 14,
    },
    {
      code: 'SKYCASTLE',
      description: 'SKYCASTLE',
      id: 24,
    },
    {
      code: 'SkyShowtime',
      description: 'SkyShowtime',
      id: 11,
    },
    {
      code: 'SCIFI',
      description: 'SYFY',
      id: 7,
    },
    {
      code: 'TELEMUNDO',
      description: 'Telemundo',
      id: 5,
    },
    {
      code: 'TELEMUNDO_MIAMI_WSCV',
      description: 'Telemundo Local Miami WSCV',
      id: 21,
    },
    {
      code: 'UKIDS',
      description: 'Universal Kids',
      id: 8,
    },
    {
      code: 'USA',
      description: 'USA',
      id: 6,
    },
  ],
  'material-types': [
    { position: 1, code: 'AFTER_EFFECTS', displayName: 'After Effects' },
    { position: 2, code: 'ARCHIVE', displayName: 'Archive' },
    { position: 33, code: 'AUDIO_MIX', displayName: 'Audio Mix' },
    { position: 3, code: 'AVID', displayName: 'AVID' },
    { position: 5, code: 'CLOSED_CAPTION', displayName: 'Closed Caption' },
    { position: 34, code: 'CUE_SHEET', displayName: 'Cue Sheet' },
    { position: 35, code: 'DESIGN_PACKAGE', displayName: 'Design Package' },
    { position: 36, code: 'DIALOGUE', displayName: 'Dialogue' },
    { position: 6, code: 'EDIT_LOG', displayName: 'Edit Log' },
    { position: 7, code: 'EPISODIC', displayName: 'Episodic' },
    { position: 8, code: 'FEATURE', displayName: 'Feature' },
    { position: 9, code: 'FONT_FILE', displayName: 'Font File' },
    { position: 10, code: 'FOOTAGE', displayName: 'Footage' },
    { position: 37, code: 'GIF', displayName: 'GIF' },
    { position: 38, code: 'GRAPHICS_IMAGE', displayName: 'Graphics Image' },
    { position: 39, code: 'GRAPHICS_VIDEO', displayName: 'Graphics Video' },
    { position: 12, code: 'INTERVIEW', displayName: 'Interview' },
    { position: 40, code: 'KEY_ART', displayName: 'Key Art' },
    { position: 41, code: 'LEGACY', displayName: 'Legacy' },
    { position: 14, code: 'MUSIC', displayName: 'Music' },
    { position: 42, code: 'OFF_AIR_RECORD', displayName: 'Off Air Record' },
    { position: 43, code: 'OFFLINE_EDIT', displayName: 'Offline Edit' },
    { position: 44, code: 'PACKAGES', displayName: 'Packages' },
    { position: 17, code: 'PHOTOGRAPHY', displayName: 'Photography' },
    { position: 18, code: 'PODCAST', displayName: 'Podcast' },
    { position: 19, code: 'POWERPOINT', displayName: 'PowerPoint' },
    { position: 20, code: 'PREMIERE_PRO', displayName: 'Premiere Pro' },
    { position: 45, code: 'PRESS', displayName: 'Press' },
    { position: 21, code: 'PRINT', displayName: 'Print' },
    { position: 22, code: 'PRO_TOOLS', displayName: 'Pro Tools' },
    { position: 23, code: 'PROMO', displayName: 'Promo' },
    { position: 24, code: 'QC_REPORT', displayName: 'QC Report' },
    { position: 26, code: 'SCRIPT', displayName: 'Script' },
    { position: 27, code: 'SFX', displayName: 'SFX' },
    { position: 28, code: 'SHOOT_REPORT', displayName: 'Shoot Report' },
    { position: 46, code: 'SHORT_FORM', displayName: 'Short Form' },
    { position: 29, code: 'SOUNDTRACK', displayName: 'Soundtrack' },
    { position: 47, code: 'STILL_IMAGE', displayName: 'Still Image' },
    { position: 30, code: 'SUBTITLE', displayName: 'Subtitle' },
    { position: 31, code: 'TRANSCRIPT', displayName: 'Transcript' },
    { position: 32, code: 'VOICE_OVER', displayName: 'Voice Over' },
    { position: 48, code: 'WORKING_FILES', displayName: 'Working Files' },
  ],
  'media-types': [
    { position: 1, code: 'VIDEO', displayName: 'Video' },
    { position: 2, code: 'AUDIO', displayName: 'Audio' },
    { position: 3, code: 'IMAGE', displayName: 'Image' },
    { position: 4, code: 'DOCUMENT', displayName: 'Document' },
    { position: 5, code: 'PROJECT', displayName: 'Project' },
  ],
  frOrderStatus: [
    {
      displayName: 'Completed',
      code: 'COMPLETED',
      position: 1,
    },
    {
      displayName: 'Errored',
      code: 'ERRORED',
      position: 2,
    },
    {
      displayName: 'Cancelled',
      code: 'CANCELLED',
      position: 3,
    },
    {
      displayName: 'In Progress',
      code: 'IN_PROGRESS',
      position: 4,
    },
    {
      displayName: 'Unknown',
      code: 'UNKNOWN',
      position: 4,
    },
  ],
  storageSystems: [
    {
      displayName: 'Mediator X',
      code: 'MEDIATOR_X',
      position: 1,
    },
    {
      displayName: 'Mediator 8',
      code: 'MEDIATOR_8',
      position: 2,
    },
    {
      displayName: 'PMAM Mediator',
      code: 'PMAM_MEDIATOR',
      position: 3,
    },
  ],
};
