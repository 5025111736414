export const formatOptions = (options: any) => options.map((option: any) => ({
  code: option.code,
  id: option.id || option.position,
  displayName: option.description || option.displayName,
  __typename: 'LookupValue',
}));

export const dropdownOptions = {
  masks: [
    { id: 1, code: 'MASKED', description: 'Masked' },
    { id: 2, code: 'UNMASKED', description: 'Unmasked' },
  ],
  'media-types': [
    { id: 1, code: 'VIDEO', description: 'Video' },
    { id: 2, code: 'AUDIO', description: 'Audio' },
    { id: 3, code: 'IMAGE', description: 'Image' },
    { id: 4, code: 'DOCUMENT', description: 'Document' },
    { id: 5, code: 'PROJECT', description: 'Project' },
  ],
  masters: [
    {
      id: 1,
      description: 'Master',
    },
    {
      id: 3,
      description: 'N/A',
    },
    {
      id: 2,
      description: 'Submaster',
    },
  ],
  'material-types': [
    { id: 1, code: 'AFTER_EFFECTS', description: 'After Effects' },
    { id: 2, code: 'ARCHIVE', description: 'Archive' },
    { id: 33, code: 'AUDIO_MIX', description: 'Audio Mix' },
    { id: 3, code: 'AVID', description: 'AVID' },
    { id: 5, code: 'CLOSED_CAPTION', description: 'Closed Caption' },
    { id: 34, code: 'CUE_SHEET', description: 'Cue Sheet' },
    { id: 35, code: 'DESIGN_PACKAGE', description: 'Design Package' },
    { id: 36, code: 'DIALOGUE', description: 'Dialogue' },
    { id: 6, code: 'EDIT_LOG', description: 'Edit Log' },
    { id: 7, code: 'EPISODIC', description: 'Episodic' },
    { id: 8, code: 'FEATURE', description: 'Feature' },
    { id: 9, code: 'FONT_FILE', description: 'Font File' },
    { id: 10, code: 'FOOTAGE', description: 'Footage' },
    { id: 37, code: 'GIF', description: 'GIF' },
    { id: 38, code: 'GRAPHICS_IMAGE', description: 'Graphics Image' },
    { id: 39, code: 'GRAPHICS_VIDEO', description: 'Graphics Video' },
    { id: 12, code: 'INTERVIEW', description: 'Interview' },
    { id: 40, code: 'KEY_ART', description: 'Key Art' },
    { id: 41, code: 'LEGACY', description: 'Legacy' },
    { id: 14, code: 'MUSIC', description: 'Music' },
    { id: 42, code: 'OFF_AIR_RECORD', description: 'Off Air Record' },
    { id: 43, code: 'OFFLINE_EDIT', description: 'Offline Edit' },
    { id: 44, code: 'PACKAGES', description: 'Packages' },
    { id: 17, code: 'PHOTOGRAPHY', description: 'Photography' },
    { id: 18, code: 'PODCAST', description: 'Podcast' },
    { id: 19, code: 'POWERPOINT', description: 'PowerPoint' },
    { id: 20, code: 'PREMIERE_PRO', description: 'Premiere Pro' },
    { id: 45, code: 'PRESS', description: 'Press' },
    { id: 21, code: 'PRINT', description: 'Print' },
    { id: 22, code: 'PRO_TOOLS', description: 'Pro Tools' },
    { id: 23, code: 'PROMO', description: 'Promo' },
    { id: 24, code: 'QC_REPORT', description: 'QC Report' },
    { id: 26, code: 'SCRIPT', description: 'Script' },
    { id: 27, code: 'SFX', description: 'SFX' },
    { id: 28, code: 'SHOOT_REPORT', description: 'Shoot Report' },
    { id: 46, code: 'SHORT_FORM', description: 'Short Form' },
    { id: 29, code: 'SOUNDTRACK', description: 'Soundtrack' },
    { id: 47, code: 'STILL_IMAGE', description: 'Still Image' },
    { id: 30, code: 'SUBTITLE', description: 'Subtitle' },
    { id: 31, code: 'TRANSCRIPT', description: 'Transcript' },
    { id: 32, code: 'VOICE_OVER', description: 'Voice Over' },
    { id: 48, code: 'WORKING_FILES', description: 'Working Files' },
  ],
  'version-types': [
    { id: 6, code: 'AFFILIATE', description: 'Affiliate' },
    { id: 38, code: 'AFFILIATE_Broadcast', description: 'Affiliate Broadcast' },
    { id: 37, code: 'AFFILIATE_CABLE', description: 'Affiliate Cable' },
    { id: 11, code: 'BARTER', description: 'Barter' },
    { id: 17, code: 'BARTER_EXTERNAL', description: 'Barter External' },
    { id: 29, code: 'BARTER_INCOMING', description: 'Barter Incoming' },
    { id: 16, code: 'BARTER_INTERNAL', description: 'Barter Internal' },
    { id: 30, code: 'BARTER_OUTGOING', description: 'Barter Outgoing' },
    { id: 3, code: 'CABLE_BUY', description: 'Cable Buy' },
    { id: 8, code: 'CONSUMER_MARKETING', description: 'Consumer Marketing' },
    { id: 28, code: 'CORE_ASSET', description: 'Core Asset' },
    { id: 10, code: 'CROSS_CHANNEL_SYMPHONY', description: 'Cross Channel/Symphony' },
    { id: 18, code: 'CROSS_CHANNEL_SYMPHONY_BRAVO', description: 'Cross Channel/Symphony Bravo On-Air' },
    { id: 19, code: 'CROSS_CHANNEL_PORTFOLIO', description: 'Cross Channel/Symphony Portfolio' },
    { id: 14, code: 'CROSS_PLATFORM', description: 'Cross-Platform' },
    { id: 4, code: 'DIGITAL', description: 'Digital' },
    { id: 21, code: 'DIGITAL_ORGANIC', description: 'Digital Organic' },
    { id: 7, code: 'EXPLORATION', description: 'Exploration' },
    { id: 27, code: 'INTERNATIONAL_AND_SYNDICATION', description: 'International and Syndication' },
    { id: 1, code: 'ON_AIR', description: 'On-Air' },
    { id: 33, code: 'PAID_DIGITAL', description: 'Paid Digital' },
    { id: 36, code: 'PAID_PODCAST', description: 'Paid Podcast' },
    { id: 35, code: 'PAID_RADIO', description: 'Paid Radio and Streaming' },
    { id: 34, code: 'PAID_SOCIAL', description: 'Paid Social' },
    { id: 32, code: 'PAID_TV', description: 'Paid TV' },
    { id: 25, code: 'PARTNER_MARKETING', description: 'Partner Marketing' },
    { id: 23, code: 'PEACOCK', description: 'Peacock' },
    { id: 24, code: 'PODCAST_ORGANIC', description: 'Podcast Organic' },
    { id: 26, code: 'PRESS', description: 'Press' },
    { id: 15, code: 'PUBLIC_RELATIONS', description: 'Public Relations' },
    { id: 12, code: 'RESEARCH', description: 'Research' },
    { id: 13, code: 'SALES', description: 'Sales' },
    { id: 9, code: 'SOCIAL', description: 'Social' },
    { id: 22, code: 'SOCIAL_ORGANIC', description: 'Social Organic' },
    { id: 31, code: 'SYMPHONY', description: 'Symphony' },
    { id: 2, code: 'VDD', description: 'VOD' },
  ],
  'secondary-form-version-types': [
    { id: 6, description: 'Affiliate' },
    { id: 38, description: 'Affiliate Broadcast' },
    { id: 37, description: 'Affiliate Cable' },
    { id: 11, description: 'Barter' },
    { id: 17, description: 'Barter External' },
    { id: 29, description: 'Barter Incoming' },
    { id: 16, description: 'Barter Internal' },
    { id: 30, description: 'Barter Outgoing' },
    { id: 3, description: 'Cable Buy' },
    { id: 8, description: 'Consumer Marketing' },
    { id: 28, description: 'Core Asset' },
    { id: 10, description: 'Cross Channel/Symphony' },
    { id: 18, description: 'Cross Channel/Symphony Bravo On-Air' },
    { id: 19, description: 'Cross Channel/Symphony Portfolio' },
    { id: 14, description: 'Cross-Platform' },
    { id: 4, description: 'Digital' },
    { id: 21, description: 'Digital Organic' },
    { id: 7, description: 'Exploration' },
    { id: 27, description: 'International and Syndication' },
    { id: 1, description: 'On-Air' },
    { id: 33, description: 'Paid Digital' },
    { id: 36, description: 'Paid Podcast' },
    { id: 35, description: 'Paid Radio and Streaming' },
    { id: 34, description: 'Paid Social' },
    { id: 32, description: 'Paid TV' },
    { id: 25, description: 'Partner Marketing' },
    { id: 23, description: 'Peacock' },
    { id: 24, description: 'Podcast Organic' },
    { id: 26, description: 'Press' },
    { id: 15, description: 'Public Relations' },
    { id: 12, description: 'Research' },
    { id: 13, description: 'Sales' },
    { id: 9, description: 'Social' },
    { id: 22, description: 'Social Organic' },
    { id: 31, description: 'Symphony' },
    { id: 2, description: 'VOD' },
  ],
  texts: [
    { id: 1, code: 'TEXTED', description: 'Texted' },
    { id: 2, code: 'TEXTLESS', description: 'Textless' },
  ],
  'storage-systems': [
    {
      id: 1,
      code: 'MEDIATOR_X',
      description: 'Mediator X',
    },
    {
      id: 2,
      code: 'MEDIATOR_8',
      description: 'Mediator 8',
    },
  ],
  networks: [
    { id: 1, code: 'BRAVO', description: 'Bravo' },
    { id: 17, code: 'CP_CABLE_LA', description: 'CP Cable LA' },
    { id: 15, code: 'CP_NEWS', description: 'CP News' },
    { id: 16, code: 'CP_PEACOCK_PORTFOLIO', description: 'CP Peacock Portfolio' },
    { id: 18, code: 'CP_TELEMUNDO', description: 'CP Telemundo' },
    { id: 2, code: 'EET', description: 'E! Entertainment' },
    { id: 19, code: 'ELG_DIGITAL', description: 'ELG Digital' },
    { id: 3, code: 'GMO_POST', description: 'GMO Post' },
    { id: 10, code: 'NBCE', description: 'NBC Entertainment' },
    { id: 13, code: 'NBC_FILM', description: 'NBC Film' },
    { id: 9, code: 'O2', description: 'Oxygen' },
    { id: 4, code: 'PEACOCK', description: 'Peacock' },
    { id: 12, code: 'Peacock_Domestic', description: 'Peacock Domestic' },
    // TODO: update Project Earth's, CP Peacock Portfolio, CP Cable LA & Elg Digital code and id when its in the database
    { id: 14, code: 'PROJECT_EARTH', description: 'Project Earth' },
    { id: 11, code: 'SkyShowtime', description: 'SkyShowtime' },
    { id: 7, code: 'SCIFI', description: 'SYFY' },
    { id: 5, code: 'TELEMUNDO', description: 'Telemundo' },
    { id: 8, code: 'UKIDS', description: 'Universal Kids' },
    { id: 6, code: 'USA', description: 'USA' },
  ],
  languages: [
    { id: 1, code: 'AFR', description: 'Afrikaans' },
    { id: 2, code: 'ALB', description: 'Albanian' },
    { id: 3, code: 'AMH', description: 'Amharic' },
    { id: 4, code: 'ARA', description: 'Arabic' },
    { id: 5, code: 'ARM', description: 'Armenian' },
    { id: 6, code: 'AZE', description: 'Azeri' },
    { id: 7, code: 'CAB', description: 'Balear dialect of Catalan' },
    { id: 8, code: 'BAQ', description: 'Basque' },
    { id: 9, code: 'BEL', description: 'Belarussian/Byelorussian' },
    { id: 10, code: 'BEN', description: 'Bengali' },
    { id: 11, code: 'BHO', description: 'Bhojpuri' },
    { id: 12, code: 'BOS', description: 'Bosnian' },
    { id: 97, code: 'BPO', description: 'Brazilian Portuguese' },
    { id: 13, code: 'BUL', description: 'Bulgarian' },
    { id: 14, code: 'BUR', description: 'Burmese' },
    { id: 15, code: 'CAN', description: 'Cantonese' },
    { id: 22, code: 'CSP', description: 'Castilian' },
    { id: 23, code: 'CAL', description: 'Catalan' },
    { id: 16, code: 'CAT', description: 'Catalan/Valencian/Balear' },
    { id: 17, code: 'CEB', description: 'Cebuano' },
    { id: 18, code: 'CHA', description: 'Chamorro' },
    { id: 20, code: 'CHL', description: 'Chilean Spanish' },
    { id: 19, code: 'CHI', description: 'Chinese' },
    { id: 21, code: 'CRO', description: 'Croatian' },
    { id: 24, code: 'CZE', description: 'Czech' },
    { id: 25, code: 'DAN', description: 'Danish' },
    { id: 26, code: 'DIV', description: 'Dhivehi' },
    { id: 27, code: 'DUT', description: 'Dutch' },
    { id: 28, code: 'DZO', description: 'Dzongkha' },
    { id: 29, code: 'ENG', description: 'English' },
    { id: 32, code: 'EST', description: 'Estonian' },
    { id: 33, code: 'FAO', description: 'Faroese' },
    { id: 93, code: 'PER', description: 'Farsi/Persian' },
    { id: 34, code: 'FIJ', description: 'Fijian' },
    { id: 35, code: 'FIN', description: 'Finnish' },
    { id: 36, code: 'FLM', description: 'Flemish' },
    { id: 38, code: 'FRT', description: 'French' },
    { id: 37, code: 'FRC', description: 'French Canadian' },
    { id: 39, code: 'FUL', description: 'Fula' },
    { id: 43, code: 'GLA', description: 'Gaelic' },
    { id: 44, code: 'GLG', description: 'Galician' },
    { id: 40, code: 'GEO', description: 'Georgian' },
    { id: 41, code: 'GER', description: 'German' },
    { id: 67, code: 'KIK', description: 'Gikuyu' },
    { id: 42, code: 'GIL', description: 'Gilbertese' },
    { id: 45, code: 'GRC', description: 'Greek' },
    { id: 46, code: 'GUJ', description: 'Gujarati' },
    { id: 48, code: 'HAU', description: 'Hausa' },
    { id: 49, code: 'HEB', description: 'Hebrew' },
    { id: 51, code: 'HIN', description: 'Hindi' },
    { id: 52, code: 'HMO', description: 'Hiri Motu' },
    { id: 53, code: 'HUN', description: 'Hungarian' },
    { id: 55, code: 'ICE', description: 'Icelandic/Islandic' },
    { id: 54, code: 'IBO', description: 'Igbo' },
    { id: 57, code: 'ILO', description: 'Ilocano' },
    { id: 50, code: 'HIL', description: 'Ilonggo' },
    { id: 58, code: 'IND', description: 'Indonesian' },
    { id: 56, code: 'KAL', description: 'Inuit/Greenlandic' },
    { id: 59, code: 'IPK', description: 'Inupiaq' },
    { id: 60, code: 'ITA', description: 'Italian' },
    { id: 62, code: 'JPN', description: 'Japanese' },
    { id: 61, code: 'JAV', description: 'Javanese' },
    { id: 63, code: 'KAN', description: 'Kannada' },
    { id: 64, code: 'KAZ', description: 'Kazakh' },
    { id: 66, code: 'KHM', description: 'Khmer' },
    { id: 68, code: 'KIN', description: 'Kinyarwanda' },
    { id: 101, code: 'RUN', description: 'Kirundi/Rundi' },
    { id: 70, code: 'KOR', description: 'Korean' },
    { id: 71, code: 'KUR', description: 'Kurdish' },
    { id: 69, code: 'KIR', description: 'Kyrgyz' },
    { id: 72, code: 'LAO', description: 'Laos' },
    { id: 110, code: 'LAS', description: 'Latin American Spanish' },
    { id: 73, code: 'LAV', description: 'Latvian' },
    { id: 74, code: 'LIT', description: 'Lithuanian' },
    { id: 75, code: 'MAC', description: 'Macedonian' },
    { id: 77, code: 'MAI', description: 'Maithali' },
    { id: 81, code: 'MAY', description: 'Malay' },
    { id: 78, code: 'MAL', description: 'Malayalam' },
    { id: 84, code: 'MLT', description: 'Maltese' },
    { id: 82, code: 'MAN', description: 'Mandarin' },
    { id: 79, code: 'MANS', description: 'Mandarin Simplified Chinese' },
    { id: 80, code: 'MAR', description: 'Marathi' },
    { id: 76, code: 'MAH', description: 'Marshallese' },
    { id: 65, code: 'MON', description: 'Mongolian/Halh' },
    { id: 83, code: 'MIS', description: 'Montenegrin' },
    { id: 85, code: 'NEP', description: 'Nepali' },
    { id: 86, code: 'NIU', description: 'Niuean' },
    { id: 88, code: 'NNO', description: 'Norwegian' },
    { id: 87, code: 'NON', description: 'Old Norse' },
    { id: 90, code: 'NA', description: 'Original Version' },
    { id: 89, code: 'ORI', description: 'Oriya' },
    { id: 92, code: 'PAU', description: 'Palauan' },
    { id: 91, code: 'PAN', description: 'Panjabi' },
    { id: 98, code: 'PUS', description: 'Pashtu' },
    { id: 95, code: 'POL', description: 'Polish' },
    { id: 96, code: 'POR', description: 'Portugals Portuguese' },
    { id: 94, code: 'PNB', description: 'Punjabi' },
    { id: 99, code: 'RAR', description: 'Rarotongan' },
    { id: 100, code: 'RUM', description: 'Romanian' },
    { id: 102, code: 'RUS', description: 'Russian' },
    { id: 107, code: 'SMO', description: 'Samoan' },
    { id: 103, code: 'SBC', description: 'Serbian' },
    { id: 108, code: 'SNA', description: 'Shona' },
    { id: 30, code: 'EDV', description: 'Silent (English Language)' },
    { id: 47, code: 'Hans', description: 'Simplified Mandarin Chinese' },
    { id: 104, code: 'SIN', description: 'Sinhala/Sinhalaese/Singhalese' },
    { id: 105, code: 'SLO', description: 'Slovakian' },
    { id: 106, code: 'SLV', description: 'Slovenian' },
    { id: 109, code: 'SOT', description: 'Sotho' },
    { id: 31, code: 'esAR', description: 'Spanish (Argentina)' },
    { id: 111, code: 'SUN', description: 'Sundanese' },
    { id: 112, code: 'SWA', description: 'Swahili' },
    { id: 113, code: 'SWE', description: 'Swedish' },
    { id: 120, code: 'TGL', description: 'Tagalog' },
    { id: 114, code: 'TAH', description: 'Tahitian' },
    { id: 115, code: 'TAI', description: 'Taiwanese' },
    { id: 119, code: 'TGK', description: 'Tajik' },
    { id: 116, code: 'TAM', description: 'Tamil' },
    { id: 117, code: 'TEL', description: 'Telugu' },
    { id: 118, code: 'TET', description: 'Tetum' },
    { id: 121, code: 'THA', description: 'Thai' },
    { id: 122, code: 'TIB', description: 'Tibetan' },
    { id: 125, code: 'TPI', description: 'Tok Pisin' },
    { id: 123, code: 'TKL', description: 'Tokelauan' },
    { id: 124, code: 'TON', description: 'Tongan' },
    { id: 127, code: 'TRK', description: 'Turkish' },
    { id: 126, code: 'TUK', description: 'Turkmen' },
    { id: 128, code: 'TVL', description: 'Tuvaluan' },
    { id: 129, code: 'UKR', description: 'Ukrainian' },
    { id: 130, code: 'URD', description: 'Urdu' },
    { id: 131, code: 'UZB', description: 'Uzbek' },
    { id: 132, code: 'MUL', description: 'Various Languages' },
    { id: 133, code: 'VIE', description: 'Vietnamese' },
    { id: 134, code: 'WEL', description: 'Welsh' },
    { id: 135, code: 'YOR', description: 'Yoruba' },
    { id: 136, code: 'ZUL', description: 'Zulu' },
  ],
  'aspect-ratios': [
    { id: 18, code: '1:1', description: '1:1' },
    { id: 19, code: '3:2', description: '3:2' },
    { id: 20, code: '3:4', description: '3:4' },
    { id: 27, code: '4:1', description: '4:1' },
    { id: 1, code: '4:3', description: '4:3' },
    { id: 26, code: '4:5', description: '4:5' },
    { id: 21, code: '5:4', description: '5:4' },
    { id: 28, code: '8:3', description: '8:3' },
    { id: 22, code: '9:16', description: '9:16' },
    { id: 24, code: '14:10', description: '14:10' },
    { id: 6, code: '16:9', description: '16:9' },
    { id: 25, code: '19:10', description: '19:10' },
    { id: 23, code: '21:9', description: '21:9' },
  ],
  'workorder-profiles': [
    {
      id: 2,
      code: 'Servicing_Test',
      description: 'Servicing_Test',
      quickRetrieve: 1,
      brand: 'EET',
      pubDefId: 28,
      deleted: 0,
      automatedDelivery: 1,
    },
    {
      id: 4,
      code: 'Product_Delivery_Test',
      description: 'Product_Delivery_Test',
      quickRetrieve: 1,
      brand: 'Bravo',
      pubDefId: 142,
      deleted: 0,
      automatedDelivery: 1,
    },
    {
      id: 8,
      code: 'Social_H264_MP4_Vantage',
      description: 'Bravo_DEV A Publication Definition "Social - mp4".',
      quickRetrieve: 0,
      brand: 'Bravo',
      pubDefId: 178,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 9,
      code: 'AVID_BRAVO_DF30_DNXHD145',
      description: 'AVID_BRAVO_DF30_DNXHD145',
      quickRetrieve: 0,
      brand: 'GMO_POST',
      pubDefId: 118,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 10,
      code: 'AVID_BRAVO_P2398_DNXHD175',
      description: 'AVID_BRAVO_P2398_DNXHD175',
      quickRetrieve: 0,
      brand: 'GMO_POST',
      pubDefId: 124,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 11,
      code: 'Peacock_MCP_Dev',
      description: 'Peacock_MCP_Dev',
      quickRetrieve: 0,
      brand: 'Peacock',
      pubDefId: 211,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 12,
      code: 'Peacock_San_Dev',
      description: 'Peacock_San_Dev testing',
      quickRetrieve: 0,
      brand: 'Peacock',
      pubDefId: 208,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 14,
      code: 'Oxygen-DEV1',
      description: 'Oxygen-DEV1',
      quickRetrieve: 0,
      brand: 'O2',
      pubDefId: 274,
      deleted: 0,
      automatedDelivery: 1,
    },
    {
      id: 15,
      code: 'SYFY-DEV1',
      description: 'SYFY-DEV1',
      quickRetrieve: 0,
      brand: 'SCIFI',
      pubDefId: 268,
      deleted: 0,
      automatedDelivery: 1,
    },
    {
      id: 16,
      code: 'Telemundo-DEV1',
      description: 'Telemundo-DEV1',
      quickRetrieve: 0,
      brand: 'TELEMUNDO',
      pubDefId: 277,
      deleted: 0,
      automatedDelivery: 1,
    },
    {
      id: 17,
      code: 'Universal Kids-DEV1',
      description: 'Universal Kids-DEV1',
      quickRetrieve: 0,
      brand: 'UKIDS',
      pubDefId: 271,
      deleted: 0,
      automatedDelivery: 1,
    },
    {
      id: 18,
      code: 'USA-DEV1',
      description: 'USA-DEV1',
      quickRetrieve: 0,
      brand: 'USA',
      pubDefId: 265,
      deleted: 0,
      automatedDelivery: 1,
    },
    {
      id: 19,
      code: 'Chad_Test_CustomName_PCM-STEREO',
      description: 'Chad Test Custom Name PCM Stereo',
      quickRetrieve: 0,
      brand: 'Bravo',
      pubDefId: 316,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 20,
      code: 'USA-DEV-Custom-Name',
      description: 'USA-DEV but with custom name',
      quickRetrieve: 0,
      brand: 'USA',
      pubDefId: 319,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 22,
      code: 'Telemundo-DEV2',
      description: 'Telemundo-DEV2',
      quickRetrieve: 0,
      brand: 'TELEMUNDO',
      pubDefId: 321,
      deleted: 0,
      automatedDelivery: 1,
    },
    {
      id: 23,
      code: 'BK-New-profile-A-Edited',
      description: 'BK-New-profile-A-Edited',
      quickRetrieve: 0,
      brand: 'Bravo',
      pubDefId: 322,
      deleted: 1,
      automatedDelivery: 1,
    },
    {
      id: 24,
      code: 'Peacock Domestic-DEV1',
      description: 'Peacock Domestic-DEV1-Desc',
      quickRetrieve: 0,
      brand: 'Peacock_Domestic',
      pubDefId: 323,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 25,
      code: 'Peacock Domestic-Dev2',
      description: 'Peacock Domestic-Dev2-Desc',
      quickRetrieve: 0,
      brand: 'Peacock_Domestic',
      pubDefId: 324,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 26,
      code: 'SkyShowtime-DEV1',
      description: 'SkyShowtime-DEV1-Desc',
      quickRetrieve: 0,
      brand: 'SkyShowtime',
      pubDefId: 325,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 27,
      code: 'SkyShowtime-DEV2',
      description: 'SkyShowtime-DEV2-Desc',
      quickRetrieve: 0,
      brand: 'SkyShowtime',
      pubDefId: 326,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 28,
      code: 'Peacock Domestic-Dev3',
      description: 'Peacock Domestic-Dev3-Desc-updated',
      quickRetrieve: 0,
      brand: 'Peacock_Domestic',
      pubDefId: 327,
      deleted: 0,
      automatedDelivery: 0,
    },
    {
      id: 29,
      code: 'Peacock Domestic-Dev4',
      description: 'Peacock Domestic-Dev4-Desc',
      quickRetrieve: 0,
      brand: 'Peacock_Domestic',
      pubDefId: 328,
      deleted: 1,
      automatedDelivery: 0,
    },
  ],
  'progress-options': [
    {
      description: 'Awaiting Components',
      code: 'Awaiting Components',
      id: 'Awaiting Components',
      mediatorState: 'Awaiting Components',
      progress: 10,
      sortAsc: 2,
      sortDesc: 11,
      source: 'mediator',
      translatorState: 'Awaiting Components',
    },
    {
      description: 'Cancelled',
      code: 'Cancelled',
      id: 'Cancelled',
      mediatorState: 'Work Order Cancelled',
      progress: 100,
      sortAsc: 14,
      sortDesc: 14,
      source: 'mediator',
      translatorState: 'Cancelled',
    },
    {
      description: 'Delivered',
      code: 'Delivered',
      id: 'Delivered',
      mediatorState: 'Delivered',
      progress: 100,
      sortAsc: 13,
      sortDesc: 0,
      source: 'mediator',
      translatorState: 'Delivered',
    },
    {
      description: 'Delivery',
      code: 'Delivery',
      id: 'Delivery',
      mediatorState: 'Delivery',
      progress: 90,
      sortAsc: 11,
      sortDesc: 2,
      source: 'mediator',
      translatorState: 'Delivery',
    },
    {
      description: 'Delivery Error',
      code: 'Delivery Error',
      id: 'Delivery Error',
      mediatorState: 'Delivery Error',
      progress: 90,
      sortAsc: 12,
      sortDesc: 1,
      source: 'mediator',
      translatorState: 'Delivery Error',
    },
    {
      description: 'Delivery Required',
      code: 'Delivery Required',
      id: 'Delivery Required',
      mediatorState: 'Delivery Required',
      progress: 80,
      sortAsc: 9,
      sortDesc: 4,
      source: 'mediator',
      translatorState: 'Delivery Required',
    },
    {
      description: 'Delivery Required Error',
      code: 'Delivery Required Error',
      id: 'Delivery Required Error',
      mediatorState: 'Delivery Required Error',
      progress: 80,
      sortAsc: 10,
      sortDesc: 3,
      source: 'mediator',
      translatorState: 'Delivery Required Error',
    },
    {
      description: 'Ordered',
      code: 'Ordered',
      id: 'Ordered',
      mediatorState: 'Ordered',
      progress: 0,
      sortAsc: 1,
      sortDesc: 12,
      source: 'mediator',
      translatorState: 'Ordered',
    },
    {
      description: 'Packaging',
      code: 'Packaging',
      id: 'Packaging',
      mediatorState: 'Packaging',
      progress: 70,
      sortAsc: 7,
      sortDesc: 6,
      source: 'mediator',
      translatorState: 'Packaging',
    },
    {
      description: 'Packaging Error',
      code: 'Packaging Error',
      id: 'Packaging Error',
      mediatorState: 'Packaging Error',
      progress: 70,
      sortAsc: 8,
      sortDesc: 5,
      source: 'mediator',
      translatorState: 'Packaging Error',
    },
    {
      description: 'Pending Cancellation',
      code: 'Pending Cancellation',
      id: 'Pending Cancellation',
      mediatorState: 'Pending Cancellation',
      progress: 90,
      sortAsc: 15,
      sortDesc: 15,
      source: 'mam',
      translatorState: 'Pending Cancellation',
    },
    {
      description: 'Placing Order',
      code: 'Placing Order',
      id: 'Placing Order',
      mediatorState: 'Placing Order',
      progress: 0,
      sortAsc: 0,
      sortDesc: 13,
      source: 'mam',
      translatorState: 'Placing Order',
    },

    {
      description: 'Restoring',
      code: 'Restoring',
      id: 'Restoring',
      mediatorState: 'Restoring',
      progress: 0,
      sortAsc: 0,
      sortDesc: 13,
      source: 'mam',
      translatorState: 'Restoring',
    },
    {
      description: 'Transcode',
      code: 'Transcode',
      id: 'Transcode',
      mediatorState: 'Transcode',
      progress: 50,
      sortAsc: 5,
      sortDesc: 8,
      source: 'mediator',
      translatorState: 'Transcode',
    },
    {
      description: 'Transcode Error',
      code: 'Transcode Error',
      id: 'Transcode Error',
      mediatorState: 'Transcode Error',
      progress: 50,
      sortAsc: 6,
      sortDesc: 7,
      source: 'mediator',
      translatorState: 'Transcode Error',
    },
    {
      description: 'Transfer',
      code: 'Transfer',
      id: 'Transfer',
      mediatorState: 'Transfer',
      progress: 30,
      sortAsc: 3,
      sortDesc: 10,
      source: 'mediator',
      translatorState: 'Transfer',
    },
    {
      description: 'Transfer Error',
      code: 'Transfer Error',
      id: 'Transfer Error',
      mediatorState: 'Transfer Error',
      progress: 30,
      sortAsc: 4,
      sortDesc: 9,
      source: 'mediator',
      translatorState: 'Transfer Error',
    },
  ],
  transformations: [
    {
      id: 4,
      code: 'ANAMORPHIC',
      description: 'Anamorphic',
    },
    {
      id: 1,
      code: 'FULL_FRAME',
      description: 'Full Frame',
    },
    {
      id: 3,
      code: 'LETTERBOX',
      description: 'Letterbox',
    },
    {
      id: 2,
      code: 'SIDE_MATTE',
      description: 'Side Matte',
    },
  ],
};
