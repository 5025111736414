import React, { useState, useEffect, useRef } from 'react';
import ConfirmModal from '../../Modal/ConfirmModal/ConfirmModal';

interface SessionManagerProps {
  warningTimeout: number;
  sessionTimeout: number;
}

const SessionManager = ({ warningTimeout, sessionTimeout }: SessionManagerProps) => {
  const workerRef = useRef(null);
  const broadcastChannel = useRef(new BroadcastChannel('session_channel'));
  const [showSessionModal, setShowSessionModal] = useState<boolean>(false);

  const handleLogout = () => {
    fetch('/logout')
      .then(() => {
        window.location.href = '/login';
      })
      .catch(() => {
        window.location.href = '/login';
      });
  };

  const handleSessionWarning = (logout: boolean) => {
    if (logout) {
      handleLogout();
      return;
    }

    setShowSessionModal(false);
  };

  const onSessionExpire = () => {
    handleLogout();
  };

  const onWarning = () => {
    setShowSessionModal(true);
  };

  useEffect(() => {
    let link: HTMLLinkElement = document.querySelector("link[rel~='icon']");

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }

    link.href = showSessionModal ? '/warning.ico' : '/favicon.ico';
  }, [showSessionModal]);

  useEffect(() => {
    // Main script
    const workerScript = `
    let timerId = null;

    self.onmessage = function (event) {
      const { action, warningTimeout, sessionTimeout } = event.data;
    
      if (action === "start") {
        const warningEndTime = Date.now() + warningTimeout;
        const sessionEndTime = Date.now() + sessionTimeout;
    
        timerId = setInterval(() => {
          const warningTime = warningEndTime - Date.now();
          const sessionTime = sessionEndTime - Date.now();
          if (warningTime <= 0) {
            self.postMessage({ action: "warning" });
          } 
    
          if (sessionTime <= 0) {
            self.postMessage({ action: "logout" });
            clearInterval(timerId);
          }
    
          self.postMessage({ action: "tick", warningTime, sessionTime });
        }, 1000);
      } else if (action === "stop") {
        clearInterval(timerId);
      }
    };
    `;

    const blob = new Blob([workerScript], { type: 'text/javascript' });
    workerRef.current = new Worker(URL.createObjectURL(blob));

    workerRef.current.onmessage = (event: any) => {
      const { action } = event.data;

      if (action === 'warning') {
        onWarning();
      }

      if (action === 'logout') {
        broadcastChannel.current.postMessage({ action: 'expire' });
        onSessionExpire();
      }
    };

    const restartTimer = () => {
      workerRef.current.postMessage({ action: 'stop' });
      workerRef.current.postMessage({ action: 'start', warningTimeout, sessionTimeout });
    };

    window.addEventListener('click', restartTimer);
    restartTimer();

    const handleBroadcastMessage = (event: any) => {
      if (event.data.action === 'expire') {
        onSessionExpire();
      }
    };

    // Handle session expiration in other tabs
    broadcastChannel.current.onmessage = handleBroadcastMessage;

    return () => {
      window.removeEventListener('click', restartTimer);
      broadcastChannel.current.close();
      workerRef.current.postMessage({ action: 'stop' });
      workerRef.current.terminate();
    };
  }, [warningTimeout, sessionTimeout]);

  return showSessionModal ? (
    <ConfirmModal
      heading="Your session is about to expire."
      text="You will be logged out in 5 minutes if no action is taken."
      cancelText="Continue Session"
      action="Log Out"
      onClose={(logout: boolean) => handleSessionWarning(logout)}
    />
  ) : null;
};

export default SessionManager;
